<template>
  <div class="md:layout-container mobOnly:max-w-window py-24 type-tag-xxs">
    <div
      class="breadcrumbs flex h-16 md:h-24 items-center truncate mobOnly:pl-24 "
    >
      <div
        v-for="(route, index) in breadcrumbs"
        :key="route.RelativePath"
        class="flex items-center flex-shrink-0 truncate"
      >
        <div
          class="uppercase transition-colors ease-in-out duration-200"
        >
          <nuxt-link :to="route.RelativePath">
            {{ route.NameInBreadcrumbs || route.Name }}
          </nuxt-link>
        </div>
        <img
          src="/zynicons/arrow-right-dark.svg"
          class="mx-8 h-16"
          height="16"
          width="16"
        >
      </div>
      <div
        class="meta uppercase transition-colors ease-in-out duration-200 pr-16 truncate"
      >
        {{ title }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';

const globalContentStore = useGlobalContentStore();
const props = defineProps<{
  breadcrumbs: any[],
  title: string,
}>();

const breadcrumbsValue = [...props.breadcrumbs, { 
  RelativePath: '',
  Name: props.title, 
}];
const host = globalContentStore.host;
const jsonLd = { 
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbsValue.map((item, index) => {
    const breadcrumbItem: any = {
      '@type': 'ListItem',
      position: index + 1,
      name: item.nameInBreadCrumbs || item.Name,
    };

    if (index < breadcrumbsValue.length - 1) {
      breadcrumbItem.item = { '@id': host + item.RelativePath };
    }

    return breadcrumbItem;
  }),
} as any;

useJsonld(jsonLd);

</script>
<style>
.breadcrumbs {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  .meta:last-child {
    a {
      margin-right: 20px;
    }
  }
}

.breadcrumbs::-webkit-scrollbar {
  display: none;
}
</style>
